<template>
  <section>
    <div class="content-header">
      <h2>College students’ sense of belonging: Dimensions and correlates </h2>
    </div>
    <div class="content-wrapper">
      <p>Colleges and universities often have significantly different graduation rates based on students’ ethnicity. <a href="https://stacks.stanford.edu/file/druid:rd771tq2209/Dabney Ingram dissertation_submitted to Stanford_060512-augmented.pdf" target="_blank">College students’ sense of belonging: Dimensions and correlates</a> by Dabney Ingram explores how systematic variation in students’ sense of belonging at college may be a factor. This work is licensed under a <a href="http://creativecommons.org/licenses/by-nc/3.0/" target="_blank">Creative Commons Attribution-Noncommercial 3.0 Unported License</a></p>
      <p>Check it out if you’d like to improve your understanding of student belonging, its components and its correlates.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
